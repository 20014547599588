@mixin on-dark-bg() {
  .bg-black,
  .bg-gray,
  .bg-gradient {
    &,
    [class*='bg-'] & {
      @content;
    }
  }
}

@mixin theme-colors($self: false) {
  @each $name, $color-scheme in $color-schemes {
    @if $self == true {
      .bg-#{$name},
      [class*='bg-'] .bg-#{$name} {
        @content($color-scheme);
      }
    } @else {
      .bg-#{$name} &,
      [class*='bg-'] .bg-#{$name} & {
        @content($color-scheme);
      }
    }
  }
}

@include theme-colors(true) using ($colors) {
  color: map-get($colors, text);

  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  strong,
  b,
  .text-hl {
    color: map-get($colors, headings);
  }

  hr {
    border-color: map-get($colors, border);
  }

  a {
    color: map-get($colors, link);
  }
}

@mixin theme-gradient {
  @each $name, $stops in $gradients {
    @if $name == $default-gradient {
      @content($stops, $name);
    } @else {
      .gradients-#{$name} & {
        @content($stops, $name);
      }
    }
  }
}

.bg-gradient {
  @include theme-gradient() using ($stops, $name) {
    @include gradient($stops, map-get($section-gradient-bg-direction, $name));
  }
}

@supports (background-clip: text) {
  .color-gradient {
    @include theme-gradient() using ($stops, $name) {
      @include gradient($stops);
      background-clip: text;
      -webkit-text-fill-color: transparent;

      // .section-header {
      //   @include gradient($stops);
      //   background-clip: text;
      //   -webkit-text-fill-color: transparent;
      // }

      .section-header::before {
        @include gradient($stops);
      }
    }
  }
}

.btn-primary {
  @include theme-gradient() using ($stops, $name) {
    @include gradient($stops, 'to right', background);
    color: #fff;
  }
}

.bg-black .bg-gradient,
.bg-gradient,
.bg-white .bg-black .bg-gradient,
.bg-white .bg-gradient {
  .btn-primary {
    background: none;
    background-color: #fff !important;
    color: $headings-color !important;
  }
}

@include theme-colors(true) using ($colors) {
  .btn-primary.plain {
    background: none;
    background-color: map-get($colors, btn-primary) !important;
    color: map-get($colors, background) !important;
  }
}

.switch-image {
  position: relative;

  img {
    transition: opacity 0.2s;
    transition-delay: 0.1s;
    position: relative;
  }

  .image-light {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 0;
  }

  .image-dark {
    z-index: 1;
  }

  .bg-black &,
  [class*='bg-'] .bg-black &,
  .menu-open & {
    img {
      transition-delay: 0.2s;
    }

    .image-dark {
      opacity: 0;
      z-index: 0;
    }
    .image-light {
      opacity: 1;
      z-index: 1;
    }
  }
}

.lottie-colorize svg {
  @include theme-colors() using ($colors) {
    @each $name, $value in $icon-colors {
      $v: unquote('');
      @if $value != '' {
        $v: unquote('="#{$value}"');
      }
      $color: map-get(map-get($colors, icon), $name);
      *[fill#{$v}] {
        fill: $color;
      }
      *[stroke#{$v}] {
        stroke: $color;
      }
    }
  }
}

.lottie {
  width: 100%;
}
