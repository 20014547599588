@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ipspc4') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ipspc4') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ipspc4##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ic-"], [class*=" ic-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ic-arrow-down {
  &:before {
    content: $ic-arrow-down; 
  }
}
.ic-arrow-left {
  &:before {
    content: $ic-arrow-left; 
  }
}
.ic-arrow-right {
  &:before {
    content: $ic-arrow-right; 
  }
}
.ic-arrow-up {
  &:before {
    content: $ic-arrow-up; 
  }
}
.ic-chevron-down {
  &:before {
    content: $ic-chevron-down; 
  }
}
.ic-chevron-left {
  &:before {
    content: $ic-chevron-left; 
  }
}
.ic-chevron-right {
  &:before {
    content: $ic-chevron-right; 
  }
}
.ic-chevron-up {
  &:before {
    content: $ic-chevron-up; 
  }
}
.ic-facebook {
  &:before {
    content: $ic-facebook; 
  }
}
.ic-instagram {
  &:before {
    content: $ic-instagram; 
  }
}
.ic-linked-in {
  &:before {
    content: $ic-linked-in; 
  }
}
.ic-plus {
  &:before {
    content: $ic-plus; 
  }
}
.ic-twitter {
  &:before {
    content: $ic-twitter; 
  }
}
.ic-xing {
  &:before {
    content: $ic-xing; 
  }
}
.ic-youtube {
  &:before {
    content: $ic-youtube; 
  }
}
.ic-wechat {
  &:before {
    content: $ic-wechat; 
  }
}
.ic-volume-mute {
  &:before {
    content: $ic-volume-mute; 
  }
}
.ic-volume {
  &:before {
    content: $ic-volume; 
  }
}
.ic-play {
  &:before {
    content: $ic-play; 
  }
}
.ic-pause {
  &:before {
    content: $ic-pause; 
  }
}

